<!--
### Form input
 -->
<script lang="ts">
  import { type ComponentProps, type Snippet } from "svelte";
  import type { HTMLInputAttributes } from "svelte/elements";
  import {
    default as Field,
    default as FormField,
  } from "../FormField/FormField.svelte";

  type InputProps = {
    /** Value of the input */
    value?: any;
    /** Optional custom validity error messages */
    errorMessages?: Partial<{ [key in keyof ValidityState]: string }>;
    /** Optional busy state */
    busy?: boolean;
    /** Whether to hide borders */
    minimal?: boolean;
    /** Expose HTML input element */
    input?: HTMLInputElement;
    /** Optional custom validity message */
    customValidity?: (value: any) => string;
    /** Optional additional content next to the input*/
    extraContent?: Snippet;
  } & ComponentProps<typeof FormField> &
    HTMLInputAttributes;

  let {
    value = $bindable(),
    errorMessages,
    busy = false,
    minimal = false,
    input,
    customValidity,
    label,
    layout,
    tip,
    class: className,
    labelContent,
    oninput,
    extraContent,
    onchange,
    ...props
  }: InputProps = $props();

  let error = $state<Maybe<string | null>>();

  const validate = () => {
    if (customValidity) {
      input?.setCustomValidity(customValidity(value));
    }

    if (input && !input.validity.valid) {
      for (const type in input.validity) {
        if ((input.validity as any)?.[type]) {
          error = (errorMessages as any)?.[type] ?? input.validationMessage;
        }
      }
    } else {
      error = null;
    }
  };

  function clearError() {
    error = null;
  }
</script>

<style>
  .input-wrap {
    display: flex;
    transition: border-color 250ms;
    &:not(.minimal) {
      border: 1px solid var(--color-border-secondary);
      border-radius: var(--radius-sm);
    }
    &:focus-within {
      border-color: var(--color-border);
    }
  }

  .input {
    --input-border: none;
    font: var(--input-font, inherit);
    line-height: var(--line-none);
    flex: 1;
    &[disabled] {
      background: none;
    }
  }

  .input-content {
    display: flex;
    align-items: center;
    color: var(--color-text-secondary);
    padding: 0 var(--size-4);
    border-left: 1px solid var(--color-border-secondary);
  }
</style>

<Field {label} {layout} {error} {busy} {tip} {labelContent} class={className}>
  <div data-testid="input-wrap" class="input-wrap" class:minimal>
    <input
      class="input --input"
      bind:this={input}
      bind:value
      disabled={busy}
      oninput={(e) => {
        oninput?.(e);
        clearError();
      }}
      onchange={(e) => {
        onchange?.(e);
        validate();
      }}
      {...props}
    />

    {#if extraContent}
      <div class="input-content">{@render extraContent()}</div>
    {/if}
  </div>
</Field>
